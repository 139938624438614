<template>
 <div class="priceMould">
  <el-form  ref="searchForm" :inline="true" :model="searchForm" >
     <el-form-item label="洗衣机类型" >
      <el-select v-model="searchForm.machine_type_id" placeholder="请选择">
        <el-option :label="item.val" :value="item.id" v-for="(item,index) in machineOptions" :key="index"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="模板名称">
      <el-input v-model="searchForm.name" placeholder="模板名称" />
    </el-form-item>
    <el-form-item class="main-form-item-float-right">
        <el-button type="primary" icon="el-icon-search" @click="getPriceMouldData">查询</el-button>
        <el-button @click="resetForm('searchForm')">重置</el-button>
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="handlerEdit({created:true})">新建</el-button>
    </el-form-item>
  </el-form>

  <el-table
    :data="tableData"
    border
    stripe>
    <el-table-column label="模板编号" prop="id" width="80px" align="center"  />
    <el-table-column label="模板名称" prop="name" min-width="160px" />
    <el-table-column label="洗衣机类型" prop="machineTypeName" min-width="120px" />
    <el-table-column label="状态" prop="status" width="80px" align="center"  >
       <template #default="scope">
        {{ scope.row.status==='1'?'编辑中':"启用" }}
      </template>
    </el-table-column>
    <el-table-column label="创建日期" prop="createTime" min-width="100px" />
    <el-table-column label="是否删除" prop="isDeleted" min-width="80px" >
        <template #default="scope">
        {{ scope.row.isDeleted==='1'?'是':"否" }}
      </template>
    </el-table-column>
    <el-table-column label="修改时间" prop="modifyTime" min-width="100px" align="center"/>
    <el-table-column label="启停用" prop="createTime" min-width="100px" >
       <template #default="scope">
         <el-switch v-model="scope.row.status"  active-value="2" inactive-value="1" @change="handleStatusChange($event,scope.row.id)"/>
      </template>
    </el-table-column>
    <el-table-column
      fixed="right"
      label="操作"
      width="130">
      <template #default="scope">
        <el-button @click="handlerEdit({data: scope.row})" type="text" size="small">编辑</el-button>
        <el-button @click="handleDel(scope.row.id)" type="text" size="small" v-if="scope.row.isDeleted==0">删除</el-button>
         <el-button @click="handleResume(scope.row.id)" type="text" size="small" v-else>恢复</el-button>
      </template>
    </el-table-column>
  </el-table>
  <div style="margin-top: 10px; text-align: right;">
    <el-pagination
      layout="total,sizes,prev, pager, next,jumper,"
      :total="pagination.total"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="pagination.pageSize"
      @current-change="handleCurrentPage" 
      @size-change="handleSizeChange"
      />
  </div>

  <el-dialog custom-class="main-el-dialog" :title="dialog.title" v-model="dialog.visibled" width="750px">
    <el-form :model="moduleFormData" ref="moduleFormData" :rules="moduleFormData.rules" label-width="120px" :inline="true">
       <el-form-item label="模板名称" prop="name" :rules="[{required: true, message: '请输入模板名称', trigger: 'blur'}]">
        <el-input v-model="moduleFormData.name" placeholder="模板名称" />
      </el-form-item>
      <el-form-item label="洗衣机类型" prop="machine_type_id" :rules="[{required: true, message: '请选择类型', trigger: 'change'}]">
        <el-select v-model="moduleFormData.machine_type_id" placeholder="请选择" @change="handleMachineChange" >
          <el-option :label="item.val" :value="item.id" v-for="(item,index) in machineOptions" :key="index"></el-option>
        </el-select>
      </el-form-item>
     
      <el-table
      :data="moduleFormData.paras" border 
      style="width: 100%">
      <el-table-column
        prop="display" label="展示名称" width="200">
        <template #default="scope"> 
          <el-form-item :prop="'paras.' + scope.$index + '.display'"  :rules='rules.display'>
             <el-input v-model="scope.row.display" placeholder="请输入名称"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column
        prop="price" label="洗衣价格" width="200">
        <template #default="scope">
          <el-form-item :prop="'paras.' + scope.$index + '.price'" :rules='rules.price'>
            <el-input v-model="scope.row.price" placeholder="请输入价格"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column
        prop="action" label="命令">
        <template #default="scope">
           <el-form-item :prop="'paras.' + scope.$index + '.action'" :rules='rules.action'>
            <el-select v-model="scope.row.action" placeholder="请选择命令" no-data-text="请先选择洗衣机类型" @change="handleActionChange()">
              <el-option :label="item.name" :value="item.value" v-for="(item,index) in actionOptions" :key="index" :disabled="item.disabled"></el-option>
            </el-select>
           </el-form-item>
        </template>
      </el-table-column>
       <el-table-column
          fixed="right"
          label="操作"
          width="120">
          <template #default="scope">
            <el-button @click="handlemouldsDel(scope.$index,scope.row.action)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
    </el-table>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" plain @click="add" style="float:left">添加</el-button>
        <el-button @click="dialog.visibled = false">取消</el-button>
        
        <el-button  type="primary" @click="submit">确定</el-button>
      </span>
    </template>
  </el-dialog>
 </div>
</template>

<script>
import { handleCatchStatus } from '../../api/handler';
export default {
  data() {
    return {
      searchForm: {
        name:'',
        machine_type_id:''
      },
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      machineOptions:[],
      actionOptions:[],
       dialog: {
        visibled: false,
        created: false,
        title: '',
      },
      moduleFormData: {
        machine_type_id:'',
        name:'',
        paras: [{
          display: '',
          price: '',
          action: ''}],
      },
       rules: {
          display: [{ required: true, message: '请输入名称', trigger: 'blur' }],
          price: [{ required: true, message: '请输入价格', trigger: 'blur' }],
          action: [{ required: true, message: '请选择命令', trigger: 'change' }],
        }
    };
  },
  created() {
  
  },
  mounted() {
    this.getPriceMouldData()
    this.getMachineTypeData()
  },
  methods: {
    // 获取列表
    getPriceMouldData(){
      let params={
          page:this.pagination.current,
          pageSize:this.pagination.pageSize,
          name:this.searchForm.name,
          machine_type_id:this.searchForm.machine_type_id
      }
      this.$api.getPriceMouldList(params)
        .then(res => {
          this.tableData=res.list
          this.pagination.total=res.total
          this.pagination.current=res.page
          this.pagination.pageSize=res.pageSize
        });
    },
    // 获取洗衣机列表
    getMachineTypeData(){
      this.$api.getMachineType().then(data=>{
          this.machineOptions=data
      })
    },
    //洗衣机选择
    handleMachineChange(id){
      this.actionOptions=[]
      let data=this.machineOptions.find(item=>{return item.id===id})
      this.moduleFormData.paras =[{ display: '',price: '',action: ''}],
       this.$api.getActionList({moduleName:data.val}).then(res=>{
         if(res.content){
           Object.keys(res.content).map(key=>{
             this.actionOptions.push({value:key,name:res.content[key]})
           })
         }
      })
    },
    // 命令选择
    handleActionChange(){
      this.$nextTick(()=>{
         this.actionOptions.forEach(item=>{
           item.disabled=false
           this.moduleFormData.paras.forEach(ite=>{
            if(item.value===ite.action){
              item.disabled=true
            }
           })
        })
      })
    },
    handlerEdit({ created=false,data={}}){
        this.dialog.visibled=true
        this.dialog.title=created?'添加':'编辑'
        this.dialog.created=created
        if(!created){
           this.moduleFormData=data
           this.moduleFormData.machine_type_id=data.machineTypeId?Number(data.machineTypeId):''
           this.$api.getActionList({moduleName:data.machineTypeName}).then(res=>{
            if(res.content){
              Object.keys(res.content).map(key=>{
                this.actionOptions.push({value:key,name:res.content[key]})
                this.handleActionChange(data.machineTypeId)
              })
            }
          })
           
        }else{
            this.moduleFormData={
              machine_type_id:'',
              name:'',
              paras: [{
                display: '',
                price: '',
                action: ''}],
          }
          this.$refs['moduleFormData'].resetFields()
        }
       
    },
    //新建/编辑
    submit(){
      this.$refs['moduleFormData'].validate((valid) => { if (valid) {
        let Action
          if(this.dialog.created){
               Action=  () =>this.$api.addPriceMould(this.moduleFormData)
          }else{
               Action= () =>this.$api.editPriceMould(this.moduleFormData)
          }  
          Action().then((Res)=>{
                this.getPriceMouldData()
                this.dialog.visibled=false
                this.moduleFormData={
                    machine_type_id:'',
                    name:'',
                    paras: [{
                      display: '',
                      price: '',
                      action: ''}],
                }
                this.$message.success(this.dialog.created?'添加成功':'修改成功')
                this.$refs['moduleFormData'].resetFields()
          }).catch(handleCatchStatus);
        }
      })
    },
    //添加价格模板
    add(){
      this.moduleFormData.paras.push({
        display: '',
        price: '',
        action: ''
       })   
    },
    //删除价格模板
    handlemouldsDel(index,type){
        this.moduleFormData.paras.splice(index,1)
       this.$nextTick(()=>{
         this.actionOptions.forEach(item=>{
            if(item.value===type){
              item.disabled=false
            }
        })
      })
    },
    // 启停用
    handleStatusChange(type,id){
      if(id){
        let Action
        if(type==2){
              Action=  () =>this.$api.statMould(id)
        }else{
              Action= () =>this.$api.stopMould(id)
        }
        Action().then(()=>{
          this.getPriceMouldData()
          this.$message.success(type==2?'启用成功':'停用成功')
        }).catch(handleCatchStatus);  
      }
    
    },
    // 分页
    handleCurrentPage(current) {
      this.pagination.current = current;
      this.getPriceMouldData();
    },
    handleSizeChange(size){
      this.pagination.pageSize = size;
      this.getPriceMouldData();
    },
    
    // 重置
    resetForm(formName) {
      this.searchForm={}
      this.getPriceMouldData()
    },
    // 恢复
    handleResume(id){
      this.$api.resumePriceMould(id).then(()=>{
          this.$message.success('恢复成功')
          this.getPriceMouldData();
      }).catch(handleCatchStatus);
    },
    // 删除
    handleDel(id) {
      this.$confirm('此操作将永久删除该内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => this.$api.delPriceMould(id))
        .then(() => {
          this.$message.success('删除成功!');
          this.getPriceMouldData();
        })
        .catch(handleCatchStatus);
    }
  }
};
</script>
